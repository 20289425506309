import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import { navigate } from 'gatsby-link';

import * as webSocketActions from '../redux/actions/webSocketActions';
import * as serviceAddressActions from '../redux/actions/serviceAddressActions';

import ResidentLoginForm from '../components/ResidentLoginForm';

function MobileLogin({ actions }) {
  return (
    <div>
      <ResidentLoginForm />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileLogin);
